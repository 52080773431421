.navbar-container {
  width: 100%;
  background-color: #006DA3; /* Change this to your desired background color */
  padding: 10px 20px; /* Adjust padding as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add a shadow for depth */
  position: fixed;
  top: 0;
  z-index: 20000;
  box-sizing: border-box;
}

.navbar {
  display: flex;
  justify-content: flex-start space-between; /* Align items to the left */
  align-items: center;
  width: 100%;
  box-sizing: border-box;

}

.nav-links {
  display: flex;
  align-items: center;
  margin-right: auto; /* Pushes the logo/text to the right */
  gap: 20px;
  margin-left: 30px
}

.navbar a {
  color: white; /* Link color */
  text-decoration: none;
  margin: 0 10px; /* Adjust spacing as needed */
}

.navbar button {
  background-color: #ff6347; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.navbar button:hover {
  background-color: #ff4500; /* Button hover color */
}

.home-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: auto; /* Aligns the button to the far right */
}

.aim-text {
  font-feature-settings: "clig" off, "liga" off;
  background: linear-gradient(
    180deg,
    #fffcfc 0%,
    #8ec2f3 58%,
    rgba(86, 165, 238, 0) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 900 50px/66% Barlow, -apple-system, Roboto, Helvetica, sans-serif;
  margin-left: 0px;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 90%;
}

.poppins-regular-light-nav {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 80%;
  color: white; 
  line-height: 1.6;
  text-align: right;
  margin-left: auto
}

.contact-info {
  display: flex;
  flex-direction: column; /* Stacks address and phone number */
  text-align: right;
  margin-left: auto;
}

@media (max-width: 767px) {
  .navbar {
    justify-content: space-between flex-start; /* Keep items spaced evenly */
    padding: 10px; /* Reduce padding on smaller screens */
    flex-direction: row; 
  }

  .nav-links {
    gap: 5px; /* Reduce gap between items for better fit */
    margin-left: 5px; /* Reduce left margin */
    overflow-x: auto; /* Allows horizontal scrolling if necessary */
    flex-wrap: nowrap; /* Prevent wrapping */
    order: 2
  }

  .contact-info {
    display: flex;
    flex-direction: column; /* Stack address and phone vertically */
    text-align: right; /* Right-align contact info */
    margin-left: auto; /* Keep contact info aligned to the right */
    order: 3; /* Place contact info after nav-links */
  }

  .poppins-regular-light-nav {
    order: 2; /* Positions the address after nav-links */
    margin-left: auto; /* Aligns the address to the right */
    display: flex;
    flex-direction: column; /* Stacks address vertically */
    text-align: right; /* Right-aligns the text */
    width: 150px
  }

  .navbar a {
    font-size: 14px; /* Smaller font size for mobile devices */
    margin: 0 5px; /* Smaller margin for compact spacing */
  }

  .navbar button {
    padding: 6px 12px; /* Smaller padding for buttons on mobile */
    font-size: 12px; /* Smaller font size for buttons */
  }

  .aim-text {
    font-size: 28px; /* Smaller font size for text */
    margin: 5px 0; /* Adjust margin for better spacing */
    margin-left: -10px;
    order: 1;
  }
}