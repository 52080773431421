/* BackgroundSlider.css */

.container-style {
  width: 100%;
  height: 100vh;
  position: relative; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
  overflow: hidden;
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5vh; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; 
}

.carousel span {
  width: 2.5rem; 
  height: 0.75rem;
  background-color: rgb(179, 178, 176);
  margin-left: 1rem;
  border-radius: 0.625rem;
  cursor: pointer;
  z-index: 3;
}
  
.inner-container {
  max-width: clamp(20rem, 50vw, 46rem);;
  width: 80%;
  position: absolute;
  z-index: 4;
  color: aliceblue;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.125rem;
  background-color: rgba(255, 255, 255, .4);
  max-height: 12.5rem;
  max-width: 40rem;
  padding: clamp(1rem, 2vw + 1rem, 2.5rem);
  box-sizing: border-box;
}
  
.aim-homescreen {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.5);
  max-width: 90%;
  font: 600 clamp(1.75rem, 3vw + 1rem, 4rem) Poppins, sans-serif;
  position: absolute;
  top: calc(50% - 12.5rem + 15px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  white-space: nowrap; 
}

.inner-container-text.poppins-regular-bs {
  color: #000000; 
  text-align: center; 
  max-width: 90%; /* Relative width */
  font-size: clamp(1.25rem, 1.2vw + 0.5rem, 2rem); /* Use rem for scalable font size */
  line-height: 1.5; /* Scalable line height */
  font-family: "Poppins", -apple-system, Roboto, Helvetica, sans-serif; 
  font-weight: 400; 
  z-index: 5; 
  margin: 0 auto; 
  padding: 1.25rem; /* Scalable padding */
  margin-top: -25px
}

.get-quote-button {
  display: block; 
  width: fit-content; 
  justify-content: center;
  align-items: center; 
  border-radius: 2.375rem;
  border-color: rgba(0, 0, 0, 1);
  border-style: solid;
  border-width: 0.125rem; 
  color: #000;
  padding: clamp(0.4rem, 1vw + 0.2rem, 0.75rem); /* Scalable padding, slightly smaller */
  font: 500 clamp(0.6rem, 0.9vw + 0.2rem, 1rem) Poppins, sans-serif; /* Scalable font size */
  margin: -0.3125rem auto 0 auto;
  text-align: center;
  text-decoration: none; 
  background-color: transparent; 
  cursor: pointer;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@media (orientation: landscape) and (max-height: 575.98px){
  .inner-container {
    top: 65%
  }

  .aim-homescreen {
    top: calc(65% - 12.5rem + 40px);
  }
}