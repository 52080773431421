.main-container {
  width: 90%; 
  max-width: 1002px;
  height: auto; 
  min-width: 300px; 
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255); 
  border-radius: 15px; 
  margin-top: 100px;
  justify-content: flex-start;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 150px
}

.dropdown-container {
  display: flex; 
  flex-wrap: wrap; 
  gap: 10px; 
  width: 100%; 
  margin-bottom: 10px; 
}

.dropdown-wrapper {
  width: calc(50% - 10px); 
  margin-bottom: 2 0px; 
}

.file-upload-wrapper {
  display: flex;
  flex-direction: column; /* Ensures label and input are stacked vertically */
}

.input,
select {
  width: 100%; 
  padding: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.inquiry-info {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  text-align: left; 
  margin-right: auto; 
}

.button-inquiry-wrapper {
  display: flex;
  justify-content: space-between; 
  width: 100%; 
  margin-top: 20px; 
  align-items: center; 
}

.button-container {
  display: flex; 
  justify-content: flex-end; 
  width: 100%; 
  margin-top: 20px; 
}

.submit-button {
  background-color: #006DA3; 
  color: white; 
  border: none; 
  padding: 10px 20px; 
  border-radius: 5px; 
  cursor: pointer; 
  font-size: 16px; 
  font-family: "Poppins", sans-serif; 
  transition: background-color 0.3s; 
}

.submit-button:hover {
  background-color: #00547e; 
}

.required {
  color: red
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 20px;
  width: 40%;
  border-radius: 8px;
  text-align: center;
}

.popup-content h3 {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: clamp(15px, 2.5vw, 24px);
}

.popup-content h4 {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: clamp(13.5px, 2vw, 18px);
}

.popup-content h5 {
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: clamp(12px, 1.5vw, 16px);
}

.popup-content button {
  padding: 10px 20px;
  background-color: #006DA3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif; 
}

@media (max-width: 767px) {
  .dropdown-wrapper {
    width: 100%; 
  }

  .main-container {
    padding: 15px; 
  }

  input,
  select {
    font-size: 14px; 
  }
}