.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  align-items: flex-start;
  margin: 40px auto;
  width: 100%; 
  padding: 0 20px; 
  margin-bottom: 150px;
  box-sizing: border-box; 
  gap: 30px;
}

.about-section {
  flex: 1; 
  max-width: 300px; 
  height: auto; 
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  margin: 60px 20px 0; 
}

.main-container {
  display: flex;
  justify-content: space-between; 
  align-items: flex-start;
  width: 100%;
  padding: 40px 20px; 
  margin: auto; 
  box-sizing: border-box; 
  gap: 20px; 
} 

.separator-line {
  width: 10px;
  height: 490px;
  background-color: #00547E;
  margin: 0 20px;
  border-radius: 17px;
  margin-top: 30px;
}

.services-section {
  flex: 1; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 600px; 
  margin-top: 40px;
}

.title-about {
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  font: 600 40px/95% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
  margin-top: 0;
  margin-bottom: 30px;
}

.title-services{
  color: #ffffff;
  font-feature-settings: "clig" off, "liga" off;
  font: 600 40px/95% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-left: 0px
}


.service-box {
  flex: 1;
  max-width: 330px;
  min-width: 200px; 
  width: 100%;
  min-height: 360px;
  height: 390px; 
  height: auto;
  border-radius: 15px;
  background-color: #006da3;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: flex-start;
  color: white;
  padding: 18px 20px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 auto; 
  flex-shrink: 0;
}

.image-container-services {
  flex-shrink: 0; 
  width: 30vw; 
  max-width: 500px; 
  height: auto; 
  overflow: hidden; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-top: 70px;
  margin-left: 50px
}

.image-container-services img {
  width: 80%; 
  height: auto; 
  object-fit: cover; 
  max-height: 50vh; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  border-radius: 10px;
}

.poppins-regular-about {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 90%;
  color: #000; 
  line-height: 1.6; 
  text-align: left; 
  margin-bottom: 20px; 
}

.poppins-regular-about-last {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 90%;
  color: #000;
  line-height: 1.6;
  margin-top: 20px; 
}

.poppins-regular-services {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 90%;
  line-height: 1.6;
}

.poppins-regular-services-light {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 90%;
  line-height: 1.6;
}

.poppins-regular-services-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 90%;
  line-height: 1.6;
}

.poppins-regular-services-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600; 
  font-style: normal;
  font-size: 90%;
  line-height: 1.6;
}

.repair-info {
  width: 100%; 
  margin: 0 auto;
}

.price-info {
  width: 100%; 
  margin: 0 auto;
}

.service-containers {
  margin-left: -100px
}

@media (max-width: 767px) {
  .services-container {
    flex-direction: column;
    align-items: center;
  }

  .about-section,
  .services-section {
    width: 90%;
    max-width: none;
    height: auto;
    margin: 20px 0;
  }

  .separator-line {
    width: 100%;
    height: 10px;
    margin: 20px 0;
  }

  .service-containers {
    flex-direction: column;
    gap: 20px;
    margin-left: 0px
  }

  .service-box {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .image-container-services {
    flex-shrink: 0; 
    width: 100%; 
    max-width: 500px; 
    height: auto; 
    overflow: hidden; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    margin-top: 0px;
    margin-left: 0px
  }

  
}


@media (min-width: 768px) and (max-width: 1420px) {
  .services-container {
    display: flex;
    justify-content: center; /* Center the sections */
    gap: 30px; /* Maintain 30px gap between sections */
    align-items: flex-start;
    margin-left: -30px; /* Remove negative margin */
    width: 100%; /* Ensure the container takes up the full width */
  }

  .about-section, 
  .services-section {
    width: 45%; /* Ensure both sections take up 45% of the width, leaving room for the gap */
    max-width: none; /* Remove max-width constraint to allow flexibility */
  }

  .about-section{
    max-width: 500px
  }

  .separator-line {
    width: 0px; /* Ensure the separator maintains its size */
  }

  .image-container-services {
    width: 0%; /* Adjust to take the full width */
    max-width: 400px; /* Reduce max-width for smaller screens */
  }

  .title-about {
    margin-top: -35px;
  }

  .services-container {
    margin-bottom: 40px
  }
}
